/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

import mq from '../style/mq';
import SEO from '../components/SEO';
import PageWrapper from '../containers/PageWrapper';
import { colors, font } from '../consts/style';
import { Container } from '../components/Elements';

export const ContentWrapper = styled(Container)`
  background: white;
  margin-top: 6rem;
`;

export const Content = styled.div`
  background: white;
  width: 50%;
  max-width: 100%;
  a {
    color: ${colors.blue};
  }
  ${mq.tablet`
    width: 100%;
  `}
`;

export const Heading = styled.h2`
  margin: 0;
  padding-bottom: 50px;
  ${font.h2};
`;

export const Body = styled.div`
  background: white;

  h3 {
    ${font.h3};
    margin: 6.4rem 0 3.2rem;
  }
  ul {
    margin-bottom: 2.8rem;
    ${font.p};
  }
  p {
    ${font.p};
    margin-bottom: 2.8rem;
    &.strong {
      font-weight: bold;
    }
  }
  a {
    text-decoration: underline;
  }
`;

const Terms = () => {
  return (
    <PageWrapper>
      <SEO
        title="Terms of  Use | Penrose Grand Del Mar"
        description="Terms of use for the Grand Del Mar website."
      />

      <ContentWrapper>
        <Content>
          <Heading>Terms of Use</Heading>
          <Body>
            <p>
              Please read these Terms of Use ("Terms", "Terms of Use") carefully
              before using the http://penroseatthegrand.com website (the
              "Service") operated by Penrose Grand Del Mar ("us", "we", or
              "our").
            </p>
            <p>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
            </p>
            <p>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
            </p>
            <h3>Links To Other Web Sites</h3>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by Penrose Grand Del Mar.
            </p>
            <p>
              Penrose Grand Del Mar has no control over, and assumes no
              responsibility for, the content, privacy policies, or practices of
              any third party web sites or services. You further acknowledge and
              agree that Penrose Grand Del Mar shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with use of or reliance
              on any such content, goods or services available on or through any
              such web sites or services.
            </p>
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </p>
            <h3>Governing Law</h3>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of California, United States, without regard to its conflict
              of law provisions.
            </p>
            <p>
              Our failure to enforce any right or provision of these Terms will
              not beconsidered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </p>
            <h3>Changes</h3>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
            <p>
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, please stop using the Service.
            </p>
            <h3>Sales</h3>
            <p>
              If you have any questions about these Terms, please contact us at
              sales@penroseatthegrand.com.
            </p>
            <h3>Website</h3>
            <p>
              This site was built by{' '}
              <a href="https://chaseohlson.com">Chase Ohlson</a>, a freelance
              developer in Los Angeles.
            </p>
          </Body>
        </Content>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Terms;
